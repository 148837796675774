import craft from "@pages/api/craft";
import QueryForm from "@queries/QueryForm.graphql";
import { unset } from "lodash";
interface IProps {
	entryId?: any;
	formId?: any;
}

const fetchForm = async ({ entryId, formId }: IProps) => {
  // entryId = entryId ? entryId.toString() : '1014988';
  entryId = entryId?.toString();
  formId = formId ? parseInt(formId, 0) : undefined;
  
  try {
    const response = await craft(QueryForm, {
      entryId: entryId,
      formId: formId
    });
    return response.marketoForms[0].formFields.map((field) => {
      const metadata = JSON.parse(field?.metadata);
      unset(field, "metadata");
      field.rowNumber = field.order;
      unset(field, "order");
      return {
        ...field,
        ...metadata,
      };
    });
  } catch (err) {
    return err;
  }
};

export default fetchForm;
